/* Use standard web-safe fonts instead of a custom font */
body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  color: white;
  font-family: Arial, Helvetica, sans-serif; /* Updated to use simple, standard fonts */
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


#root {
  width: 100vw;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
}

.container-chat {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
}

.chat-Historyc {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20%;
  width: 3.5vw;
  height: 3.5vw;
}

.chat-Historyo {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4%;
  width: 30vw;
  height: 45vh;
}

.placeholder1::placeholder {
  color: white;
  opacity: 1;
}

.placeholder2::placeholder {
  color: rgba(219, 168, 66, 255);
  text-align: center;
}

.placeholder3::placeholder {
  color: white;
  opacity: 1;
  font-size: 15px;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.icon {
  position: relative;
  margin-left: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 13px;
  height: 13px;
}

.icon span {
  width: 3px;
  height: 100%;
  background-color: orange;
  border-radius: 3px;
  content: '';
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3);
  }
  30% {
    transform: scaleY(1);
  }
  60% {
    transform: scaleY(0.5);
  }
  80% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(0.6);
  }
}

.span-prop {
  width: 3px;
  height: 100%;
  background-color: orange;
  border-radius: 3px;
  animation: bounce 2.2s ease infinite alternate;
  content: '';
}

span:nth-of-type(2) {
  animation-delay: -2.2s;
}

span:nth-of-type(3) {
  animation-delay: -3.7s;
}


.convai-holder {
  margin: 0 auto;
  position: relative;
}

.convai-holder canvas {
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
}

.canvas-container {
  position: relative;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

.npc-message {
  background: rgba(76, 175, 80, 0.2);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.ChatBubble {
  position: relative;
  top: 10vh;
  left: 20vw;
  z-index: 9999;
}

.user-message {
  background: rgba(33, 150, 243, 0.2);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.talk-button {
  transition: all 0.3s ease;
}

.talk-button:hover {
  transform: scale(1.05);
}

.chat-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.chat-input {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;   
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.mic-button {
  position: absolute;
  right: 20px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.chat-bubble-container {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 15px;
}

@media (max-width: 768px) {
  .container {
    position: fixed !important;
    bottom: 0 !important;
  }
  
  .container-chat {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Desktop Styles */
.desktop-layout {
  width: 800px;
  height: 800px;
  display: flex;
  flex-direction: row;
}

.desktop-layout .canvas-container {
  flex: 1;
  height: 100%;
  right: 50px;
  

}

.desktop-layout .chat-bubble-container {
  top: 500px;
  left: 200px;
  width: 660px;
  position: relative;
}

/* Mobile Styles */
.mobile-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
}

.mobile-layout .canvas-container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.mobile-layout .chat-bubble-container {
  position: relative;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 80%;
  min-height: 100px;
  max-height: 400px;
  background: rgba(0, 0, 0, 0.9);
  border-top: 2px solid #FFFFFF;
  overflow-y: auto;
  padding: 10px;
  margin: 10px 0;
  z-index: 2;
}

/* Add this to adjust the content inside */
.mobile-layout .container-chat {
  padding: 5px;
  margin: 0;
}

/* Media Query for any additional mobile adjustments */
@media (max-width: 768px) {
  .mobile-layout {
    margin-bottom: 120px;
  }
  
  .chat-bubble-container {
    max-height: 180px;
    overflow-y: auto;
  }
}

/* Only chat interface elements should be interactive */
.chat-interface {
  pointer-events: auto;
  touch-action: auto;
  z-index: 1000;
}
